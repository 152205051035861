import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Layout, Col, Row, Image, Typography, Button, Card, Space} from 'antd';

import Tags from '../../../Article/tags'
import './articles.less'

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

export default function Blogs(props) {

  const data = useStaticQuery(
    graphql`
      query abc {
        allContentfulArticle(sort: {fields: [publishDate], order: DESC}) {
          nodes {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            heroImage {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 424
                height: 212
              )
            }
            description
          }
        }
      }
    `
  )

  const posts = data.allContentfulArticle.nodes

  function trimDescription(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }

  }

  return (
    <Content className='articles-root'>
      <Row className='articles-content' xs={24} md={14}>
        <Typography>
          <Title level={2} className='articles-title'>
            Articles
          </Title>
          {/* <Paragraph className='articles-subtitle'>
              A company having one or multiple functions of
          </Paragraph> */}
          <Row className='article-list' xs={24} md={14}>
            {posts.map((post) => 
              <Card 
                hoverable 
                className="article" 
                key={post.slug} xs={24} sm={24} lg={12} xl={6} 
                cover={<GatsbyImage alt="" image={post.heroImage.gatsbyImageData}/>}
              > 
              <Link to={`/articles/${post.slug}`} className="link"> 
                <div style={{padding: 16}}>      
                  <Title level={4} className='article-title'>{post.title}</Title>
                  <Text className="article-description" >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: trimDescription(post.description, 120),
                      }}
                    />
                  </Text>
                </div> 
                </Link>  
              </Card>
            )}
          </Row>
        </Typography>
      </Row>
    </Content>
  )
}

// export const pageQuery = graphql`
//   query HomeQuery9 {
//     allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
//       nodes {
//         title
//         slug
//         publishDate(formatString: "MMMM Do, YYYY")
//         tags
//         heroImage {
//           gatsbyImageData(
//             layout: FULL_WIDTH
//             placeholder: BLURRED
//             width: 424
//             height: 212
//           )
//         }
//         description {
//           childMarkdownRemark {
//             html
//           }
//         }
//       }
//     }
//   }
// `

