import React from 'react'
import {Row} from 'antd';

const Item = (number, type, text) => (
  <div style={{textAlign: 'center', color: 'white', marginBottom: 32, marginTop: 24}}>
    <div className='hero-number-value'>{number}</div>
    <div style={{fontSize: 20, fontFamily:'Futura Medium', marginTop: -16}}>{type}</div>
    <div style={{fontSize: 20, fontFamily:'Futura Medium', marginTop: -8}}>{text}</div>
  </div>
)

export default function Numbers() {
  return (
    <Row className='hero-numbers'>
      {Item('30%', 'Increase', 'employees Productivity')}
      {Item('2x', 'Faster', 'Resolution Time')}
      {Item('70%', 'Increase',  'Works Visibility')}
    </Row>
  )
}
