import React from 'react'
import {Col, Divider} from 'antd'

import Seo from '../Seo'
import Hero from './components/Hero'
import WhatIs from './components/WhatIs'
import Feature from './components/Feature'
import HowWorks from './components/HowWorks'
import WhoIsFor from './components/WhoIsFor'
import GetStarted from './components/GetStarted'
import Articles from './components/Articles'

import './home.less'

export default function Home() {
  let location = null
  if (typeof window !== `undefined`){
    location = window.location
  }

  return (
    <Col centered className='home-main' >
      <Seo
        title={"Field Workforce Analytic | Employee Tracking | Survey App"}
        description={"FieldNext offers the latest mobile-enabled software for field employee tracking, monitoring, reporting, attendance, expense, visit, and task management."}
        // image={`http:${post.heroImage.resize.src}`}
        type="Product"
        origin={location != null ? location.origin : null}
        pathname={location != null ? location.pathname : null}
      />
      <Hero/>
      <WhatIs/>
      <Feature/>
      {/* <HowWorks/>
      <Divider/> */}
      <WhoIsFor/>
      <Divider/>
      <Articles/>
      <GetStarted/>
    </Col>
  )
}
