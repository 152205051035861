import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import HowWorksImg from '../../../../images/how-gatsby-works.webp'

import './howworks.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function HowWorks() {
  return (
    <Content className='howworks-root'>
      <Row className='howworks-content' xs={24} md={14}>
        <Typography>
          <Title level={2} style={{fontFamily: 'Futura Bold', fontSize: 36, lineHeight: 1.1, textAlign: "center", marginTop: 24, marginBottom: 24}}>
            How FieldNext Works
          </Title>
        </Typography>
        <Image
              width={600}
              src={HowWorksImg}
              preview={false}
              // preview={{
              //   src: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
              // }}
            />
      </Row>
    </Content>
  )
}
