import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import {DatabaseTwoTone, MobileTwoTone, CustomerServiceTwoTone} from '@ant-design/icons';
import Item from './Item'

import './whatis.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function WhatIs() {
  return (
    <Content className='whatis-root'>
      <Row className='whatis-content' xs={24}>
          <Typography>
            <Title level={2} className='whatis-title'>What is FieldNext?</Title>
          </Typography>
          <Row justify='space-between' style={{marginTop: 32, alignItems: 'center', alignItems: "flex-start"}}>
            <Item 
              icon={<DatabaseTwoTone className='whatis-icon'/>}
              title="FWM Platform"
              message="Field Workforce Management (FWM) aimed to improve workforce management in remote locations for sales, service, and project related tasks."
            />
            <Item 
              icon={<MobileTwoTone className='whatis-icon'/>}
              title="Web & Mobile Apps"
              message="A unified and secure cloud pltaform connected with FieldNext's web and mobile apps for usage of managers and employees respectively.<ToDo>"
            />
            <Item 
              icon={<CustomerServiceTwoTone className='whatis-icon'/>}
              title="Supports"
              message="Our devoted and skilled assistance will help you resolve any onboarding, technical, or operational issues quickly. Our clients are fully supported till they are able to complete their work with it."
            />
          </Row>
      </Row>
    </Content>
  )
}
