import React, {useState} from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import HeroImage from '../../../../images/hero.png'
import { PlayCircleOutlined } from '@ant-design/icons';
import VideoModal from 'react-modal'
import './hero.less'
import Numbers from './Numbers';
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function Hero() {
  const [openModal, setOpenModal] = useState(false)

  return (
    <Content className='hero-root'>
      <Col className='hero-content' >
        <Row style={{alignItems: 'center'}}>
          <Col className='hero-message' xs={24} md={13}>
            <Typography>
              <Title className="hero-title">Workforce Analytic for your Field Operation</Title>
              <Paragraph className='hero-subtitle'>
                Know how your team works in the field. Empower your team, develop healthy work habits, and streamline processes to achieve exceptional results.
              </Paragraph>
              <Row className='hero-buttons'>
                <Space size="large">
                  <Button size="large" shape="round" href="/signup">Get Started</Button>
                  <Space >
                    <Button size="large" shape="circle" icon={<PlayCircleOutlined />} onClick={()=>setOpenModal(true)} />
                    Overview video
                  </Space>
                </Space>
              </Row>
            </Typography>
          </Col>
          <Col className='hero-image-container' xs={24} md={11}>
            <Image
              width={450}
              src={HeroImage}
              preview={false}
            />
          </Col>
        </Row>
        <Row style={{justifyContent: 'center', marginBottom: 72}}>
          <Numbers/>
        </Row>
      </Col>
      <VideoModal
        className="modal"
        id={"fieldnext-intro"}
        contentLabel='Video'
        isOpen={openModal}
        onRequestClose={()=>setOpenModal(false)}
        shouldCloseOnOverlayClick={true}
      >
        <iframe 
          className='iFrame'
          src="https://www.youtube.com/embed/qjqHoKtzj_w?rel=0&autoplay=1&controls=1&disablekb=0&showinfo=0"
          frameBorder="0" 
          allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
          tabIndex='-1'
        />
      </VideoModal>
    </Content>
  )
}


