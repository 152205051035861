import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text, Link } = Typography;

export default function Item({icon, title, message, link=""}) {
  return (
    <Col xs={24} lg={11} style={{marginBottom: 48}}>
      {icon}
      <Typography>
        <Title level={3} className='feature-item-title'>{title}</Title>
      </Typography>
      <Paragraph style={{lineHeight: 1.4, color: "#606060"}}>
        {message}
      </Paragraph>
      {/* <Button type="link" icon={<ArrowRightOutlined />} style={{float: "right", marginTop: -16}}>More</Button> */}
      <a className="feature-link" href={link}>Learn more <ArrowRightOutlined /></a>
    </Col>
  )
}
