import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space } from 'antd';
import Item from './Item'
import {
  EyeOutlined,
  AimOutlined,
  FileDoneOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  DollarOutlined,
  FormOutlined,
  AreaChartOutlined,
  ProfileOutlined,
  EnvironmentOutlined,
  SmileOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';

import './feature.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;


export default function Feature() {
  return (
    <Content className='feature-root'>
      <Row className='feature-content' xs={24} md={14}>
        <Typography>
          <div className='hint'>FEATURES</div>
          <Title level={1} className="feature-title">
            All the hardest parts of managing field employees and works, made just a little easier.
          </Title>
          <Paragraph className="feature-sub-title">
            Tracking. Attendance. Fullfilment. Expense. Performance. Productivity. Analytics, Security. Integrations. Accessibility. We’ve got it covered for you. FieldNext makes the hardest parts of field operation management simple, leaving you more time focusing on your business.
          </Paragraph>
        </Typography>
        <Row justify='space-between' style={{ marginTop: 32, alignItems: 'center', alignItems: "flex-start" }}>
          <Item
            icon={<EyeOutlined className='feature-icon' />}
            title="Live location tracking"
            message="Realtime geographical presence, distribution and movement of your field workforce is easily possible with the FieldNext. These help an enterprises in better employees management, works visibility and improves operational efficiency and producttivity."
            link="/features/employee-tracking"
          />
          <Item
            icon={<FileDoneOutlined className='feature-icon' />}
            title="Field visits reporting"
            message="Field executives can submit visit report from the client location through FieldNext mobile app. Multiple custom report forms with flexible format including photos, signature and barcodes, to meet any type of task."
            link="/features/field-task-automation"
          />
          <Item
            icon={<FieldTimeOutlined className='feature-icon' />}
            title="Reliable and easy remote attendance"
            message="Geotagged and selfie based remote attendance by field employess through their FieldNext app. Realtime updates of employess attenance will be avaiable to managers and admin."
            link="/features/field-employee-attendance"
          />
          <Item
            icon={<DollarOutlined className='feature-icon' />}
            title="Expenses reporting for reimbursement"
            message="Numerous types of expenses can be easily applied by executives on the move, right from their smartphones. They could have their historical claim records in the app for refernece and followup anytime."
            link="/features/expense-management"
          />
          <Item
            icon={<FormOutlined className='feature-icon' />}
            title="Custom forms for field tasks"
            message="Every enterprises having different nature of works and process. So FieldNext provides simple but powerful Custom Form Builder with rich form fields including photos, signature and barcodes for any types of report."
            link="/features/custom-task-form"
          />
          <Item
            icon={<AreaChartOutlined className='feature-icon' />}
            title="Analytics and reports"
            message="Its provides field operations insights to managers for quick actions, on the realtime basis. Detailed field activities informations can also be immediately retrived or scheduled to get automatically, for analysis or processing for own internal reporting."
            link="/features/field-task-analytic-reporting"
          />
          <Item
            icon={<ProfileOutlined className='feature-icon' />}
            title="Sales Order and Product Catalog"
            message="Sales orders can be booked at the client location and send to backoffice/store on the realtime basis. So that quick further actions or delivery improve client experience. FieldNext App have all products details for display or better explaination to a client."
            link="/features/custom-survey-form"
          />
          <Item
            icon={<ThunderboltOutlined className='feature-icon' />}
            title="Battery friendly mobile app"
            message="FieldNext mobile app is intelligent and requires least mobile battery consumption, so that executives can smoothly work in the field for longer period."
            link="/features/battery-friendly-app"
          />
          <Item
            icon={<EnvironmentOutlined className='feature-icon' />}
            title="Geo-Tagged dataset for better visibility"
            message="All activties in the FieldNext app are geo-tagged. It greatly helps in better visibility, analytic and planning for current and future activities."
            link="/features/geo-tagging-data"
          />
          <Item
            icon={<SmileOutlined className='feature-icon' />}
            title="No internet, no worry"
            message="FieldNext mobile app works smoothly in the no mobile data network. Exceutives can continue working and report submitting into the app and whenever the mobile gets the data network then all the informatios will automatically uploaded for access of managers or backend office."
            link="/features/work-in-no-internet"
          />
        </Row>
      </Row>
    </Content>
  )
}
