import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import Item from './Item'
import SalesImg from '../../../../images/sales.jpg'
import ServiceImg from '../../../../images/services.jpg'
import OperationImg from '../../../../images/usecase/construction.jpg'

import './whoisfor.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function HowWorks() {
  return (
    <Content className='whoisfor-root'>
      <Row className='whoisfor-content' xs={24} md={14}>
        <Typography>
          <div className='hint'>USE CASES</div>
          <Title level={2} className='whoisfor-title'>
            Who is FieldNext for?
          </Title>
          <Paragraph className='whoisfor-subtitle'>
              A company having one or multiple functions of
          </Paragraph>
          <Row justify='space-between' style={{marginTop: 72, alignItems: 'center', alignItems: "flex-start"}}>
            <Item
              image={SalesImg}
              title="Sales and marketing"
              message="Sales & Marketing is a backbone of every manufacturing industries, therefore field sales force movements, client engagement and job reporting are vital."
              list={["Efficient sales executive tracking","Instant sales/order reports","Higher sales and profit"]}
            />
            <Item
              image={ServiceImg}
              title="Service and maintenance"
              message="It requires optimum utilisation of employees, low service expenses, structured & analytical reporting and satisfactory services to the customers."
              list={["Monitor technicians and services","Digital service report forms","Minimise expenses & happy customers"]}
            />
            <Item
              image={OperationImg}
              title="Project and construction"
              message="A quality & timely deleivery with optimum expenses, requires a efficiently monitoring of workers, supervisers, materials and work progress in this industry."
              list={["Realtime works monitoring","Employees and materials monitoring","Reduce expenses & delivery time"]}
            />
          </Row>
        </Typography>
      </Row>
    </Content>
  )
}
