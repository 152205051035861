import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';

const { Title, Paragraph, Text, Link } = Typography;

export default function Item({icon, title, message}) {
  return (
    <Col xs={24} lg={7} className='whatis-item'>
      {icon}
      <Typography>
        <Title level={3} style={{fontFamily: 'Futura Medium', lineHeight: 1.1}}>{title}</Title>
      </Typography>
      <Paragraph style={{lineHeight: 1.4, color: "#606060"}}>
        {message}
      </Paragraph>
    </Col>
  )
}