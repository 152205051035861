import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import { List } from 'antd/lib/form/Form';
import {CheckCircleFilled} from '@ant-design/icons';
const { Title, Paragraph, Text, Link } = Typography;

export default function Item({image, title, message, list=[]}) {
  return (
    <Col xs={24} lg={7} style={{marginBottom: 48}}>
      <Image
        // width={280}
        src={image}
        preview={false}/>
      <Typography>
        <Title level={3} className='whoisfor-item-title'>{title}</Title>
      </Typography>
      <Paragraph style={{lineHeight: 1.4, color:"#606060"}}>
        {message}
      </Paragraph>
      {list.map(item => (
        <div style={{paddingBottom: 4, color:"#606060", alignItems: 'center'}}><CheckCircleFilled/>  {item}</div>
      ))}
    </Col>
  )
}